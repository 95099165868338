import ajax from "./ajax"

// 登陆
export function accountLogin(data){
    return ajax({
        url:'/api/member/accountLogin',
        method:'post',
        data
    })
}

//忘记密码
export function forgetPassword(params){
    return ajax({
        url:'/api/company/forgetPassword',
        method:'put',
        params
    })
}

//退出登陆
export function logout(params){
    return ajax({
        url:'/api/member/logout',
        method:'get',
        params
    })
}
